/* eslint-disable radix */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

import { useAppState } from '../../../store/appstate';
import orderActions from '../../../store/order/actions';
import { Order } from '../../../types/order/Order';
import { OrderArticleRow } from '../../../types/order/OrderArticleRow';
import ResponseError from '../../../types/response/ResponseError';
import { LinkData } from '../../../types/response/LinkData';
import { formatNumber } from '../../../services/helpers/numberFormats';

import Money from '../../atoms/Money/Money';
import Spinner from '../../atoms/Spinner/Spinner';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import Card from '../../atoms/Card/Card';
import CardBody from '../../atoms/Card/CardBody';
import LayoutContainer from '../../atoms/Modal/LayoutContainer';
import LayoutPosition from '../../atoms/Modal/LayoutPosition';
import StickyNote from '../../atoms/StickyNote/StickyNote';
import Label from '../../atoms/Label/Label';
import DateTime from '../../atoms/DateTime/DateTime';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Status from '../../atoms/Status/Status';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import Total from '../../atoms/Money/Total';
import VAT from '../../atoms/Money/VAT';
import StatusTypes from '../../../types/StatusTypes';
import { ErrorType } from '../../../types/response/ErrorCodes';
import { PurchaseResponseData } from '../../../types/response/PurchaseResponseData';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';
import Table from '../../atoms/Table/Table';
import { OrderCaptureItem } from '../../../types/order/OrderCaptureItem';
import { OrderActivationItem } from '../../../types/order/OrderActivationItem';

interface Props {
    order: Order;
    captureLink?: LinkData;
    hide: () => void;
    data: OrderArticleRow[];
    theme: DefaultTheme;
}

interface EditableProps {
    originalValue: number;
    rowId: string;
    isEditable: boolean;
    onUpdateQuantity: (rowId: string, quantity: number) => void;
}

const EditableCell = ({ originalValue, onUpdateQuantity, rowId, isEditable }: EditableProps) => {
    const [value, setValue] = useState(originalValue);
    const ifActivating = useAppState<boolean>(s => s.order.isBusy);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueToSet = Math.max(
            parseInt(e.target.min),
            Math.min(parseInt(e.target.max), parseInt(e.target.value))
        );

        setValue(valueToSet);
        onUpdateQuantity(rowId, valueToSet);
    };

    const getValue = () => {
        if (isEditable && value === 0) {
            setValue(originalValue);
            return value;
        }
        if (!isEditable && value !== 0) {
            setValue(0);
            return 0;
        }
        return value;
    };

    return (
        <QuantityInputWrapper>
            <QuantityInput
                value={getValue()}
                onChange={e => onChange(e)}
                type="number"
                min="1"
                max={originalValue}
                disabled={originalValue === 1 || ifActivating || !isEditable}
            />
            <span> of {originalValue}</span>
        </QuantityInputWrapper>
    );
};

const ActivateOrder: React.FC<Props> = ({ order, data, hide, captureLink, theme }: Props) => {
    const dispatch = useAppDispatch();
    const isBusy = useAppState<boolean>(s => s.order.isBusy);
    const error = useAppState<ResponseError | undefined>(s => s.order.error);

    const [selectedRows, setSelectedRows] = useState([] as OrderArticleRow[]);
    const [orderActivationItems, setOrderActivationItems] = useState([] as OrderActivationItem[]);

    const toActivate = orderActivationItems ? orderActivationItems.map(x => x.rowid) : [];
    const getActivateQuantity = (id: string) => {
        return orderActivationItems.find(item => item.rowid === id)?.quantity || 0;
    };

    const RenderOrderValues = React.memo(
        ({ rowsToCalculate, currentOrder }: { rowsToCalculate: string[]; currentOrder: Order }) => {
            const totalOrderValue = (currentOrder.purchases ?? ([] as PurchaseResponseData[]))
                .flatMap(x => x.data.rows)
                .filter(x => rowsToCalculate.includes(x.rowId))
                .map(x => x.price * getActivateQuantity(x.rowId))
                .reduce((a, b) => a + b, 0);

            return (
                <StickyNote padding>
                    <Label>Activated value</Label>
                    <Total data-testid="activatedOrderValue">
                        {formatNumber(totalOrderValue)}
                    </Total>{' '}
                    <span>{currentOrder.currency}</span>
                    <Label>Original Order value</Label>
                    <Money testId="originalOrderValue">{currentOrder.totalAmount}</Money>
                </StickyNote>
            );
        }
    );

    const setSelectedRowsCallBack = useCallback(
        (rows: OrderArticleRow[]) => {
            if (selectedRows.length !== rows.length) setSelectedRows(rows);
        },
        [setSelectedRows, selectedRows]
    );

    const onUpdateQuantity = (rowId: string, quantity: number) => {
        setOrderActivationItems(rows =>
            rows.map(row => (row.rowid === rowId ? { ...row, quantity } : row))
        );
    };

    const onCaptureOrder = () => {
        if (!captureLink) return;

        const amount =
            orderActivationItems.reduce(
                (sum, row) => sum + Math.round(row.quantity * row.unitPrice * 100),
                0
            ) / 100;

        const orderCaptureItems = orderActivationItems.map(i => {
            return {
                id: i.id,
                unitPrice: i.unitPrice,
                description: i.description,
                quantity: i.quantity,
                vat: i.vat,
            } as OrderCaptureItem;
        });

        dispatch(
            orderActions.captureOrder(captureLink, {
                amount,
                items: orderCaptureItems,
            })
        );
    };

    const columns = useMemo(
        () => [
            {
                header: 'Description',
                accessorKey: 'description',
                cell: (props: { row: { original: OrderArticleRow } }) => {
                    return (
                        <ClickableLabel htmlFor={props.row.original.rowId}>
                            {props.row.original.description ?? '-'}
                        </ClickableLabel>
                    );
                },
            },
            {
                meta: {
                    style: {
                        width: '11rem',
                    },
                },
                header: 'Quantity',
                accessorKey: 'quantity',
                cell: (props: {
                    row: { getIsSelected: () => boolean; original: OrderArticleRow };
                }) => {
                    const { quantity, rowId } = props.row.original;

                    return (
                        <EditableCell
                            isEditable={props.row.getIsSelected()}
                            originalValue={quantity}
                            rowId={rowId}
                            onUpdateQuantity={onUpdateQuantity}
                        />
                    );
                },
            },
            {
                meta: {
                    style: {
                        textAlign: 'right',
                    },
                },
                header: 'Unit price',
                accessorKey: 'price',
                cell: (props: { row: { original: OrderArticleRow } }) => {
                    return <Money>{props.row.original.price ?? 0}</Money>;
                },
            },
            {
                meta: {
                    style: {
                        textAlign: 'right',
                    },
                },
                header: 'VAT',
                accessorKey: 'vatRate',
                cell: (props: { row: { original: OrderArticleRow } }) => {
                    return (
                        <VAT
                            amount={props.row.original.vatAmount ?? 0}
                            rate={props.row.original.vatRate ?? 0}
                        />
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        setOrderActivationItems(c =>
            selectedRows.map(r => {
                const rowSetToActivate = c.filter(
                    x => x.rowid === r.rowId
                )[0] as OrderActivationItem;

                return {
                    rowid: r.rowId,
                    id: r.articleNumber,
                    unitPrice: r.price,
                    description: r.description,
                    quantity: rowSetToActivate ? rowSetToActivate.quantity : r.quantity,
                    vat: r.vatRate,
                } as OrderActivationItem;
            })
        );
    }, [selectedRows]);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Activate articles</HeaderTitle>
                <OrderNumber>{order.orderNumber}</OrderNumber>
                <Status type={StatusTypes.Order} status={order.status} />
            </ModalContainer>

            <ModalContainer position="content">
                <LayoutContainer>
                    <LayoutPosition position="left">
                        {data && data.length > 0 && (
                            <Card>
                                <CardBody>
                                    <Table<OrderArticleRow>
                                        disableTable={isBusy}
                                        onSelectedRowsChange={setSelectedRowsCallBack}
                                        selectableProperty={item => item.rowId}
                                        data={data}
                                        columns={columns}
                                        stateFulData
                                        initialCheckAll
                                    />
                                </CardBody>
                            </Card>
                        )}
                    </LayoutPosition>
                    <LayoutPosition position="right">
                        <RenderOrderValues
                            rowsToCalculate={toActivate}
                            currentOrder={order}
                        ></RenderOrderValues>

                        {data[0]?.purchaseId && (
                            <>
                                <Label>Purchase identifier</Label>
                                <div>{data[0].purchaseId}</div>
                            </>
                        )}

                        <Label>Payment method</Label>
                        <div>{order.paymentMethod}</div>
                        <Label>Created</Label>
                        <DateTime>{order.placedAt}</DateTime>
                    </LayoutPosition>
                </LayoutContainer>
            </ModalContainer>

            {error && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={ErrorType.ActivateInvoice} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button disabled={isBusy} onClick={hide} large>
                        Cancel
                    </Button>
                    <Button
                        onClick={onCaptureOrder}
                        primary
                        large
                        disabled={isBusy || selectedRows.length <= 0}
                        data-testid="activate-order-button"
                    >
                        {isBusy ? (
                            <Spinner color={theme.colors.light} size={8} loading />
                        ) : (
                            <span>Activate</span>
                        )}
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const OrderNumber = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
`;

const QuantityInputWrapper = styled.div`
    width: 11rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    && span {
        margin-left: 0.8rem;
        align-self: center;
    }
`;

const QuantityInput = styled.input`
    &::-webkit-inner-spin-button {
        opacity: 1;
    }

    border: 0.1rem solid ${props => props.theme.colors.border};
    outline: none;

    &:focus {
        border: 0.1rem solid ${props => props.theme.colors.primary};
        outline: none;
    }

    width: 5rem;
    padding: 0.5rem;
`;

const ClickableLabel = styled.label`
    cursor: pointer;
`;

export default withTheme(ActivateOrder) as React.ComponentType<Omit<Props, 'theme'>>;


