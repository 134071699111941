import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { WebhookState } from '../../../../store/admin/webhooks/webhooksSlice';
import { useAppState } from '../../../../store/appstate';
import FormRow from '../../../atoms/Form/FormRow';
import GridInput from '../../../atoms/Input/GridInput';
import SelectInput from '../../../atoms/Input/SelectInput';
import HeaderTitle from '../../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../../atoms/Modal/ModalContainer';
import FooterButtonContainer from '../../../atoms/Modal/FooterButtonContainer';
import Button from '../../../atoms/Button/Button';
import Spinner from '../../../atoms/Spinner/Spinner';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import { Webhook } from '../../../../types/Webhook';

interface FormProps {
    onSave: (webhook: Webhook, payload: string) => void;
    hide: () => void;
    webhook: Webhook | undefined;
    theme: DefaultTheme;
}

type Inputs = {
    eventBody: string;
};

enum AuthorizationMethod {
    None = 'None',
    Basic = 'Basic',
}

interface EventType {
    type: string;
    payload: unknown;
}

const events: EventType[] = [
    {
        type: 'walley:order:created',
        payload: {
            Type: 'walley:order:created',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Amount: 30,
                Currency: 'SEK',
                Status: 'Authorized',
                AuthorizationId: 'c31e9218-6a19-4b2f-8992-affc9dca1c41',
                CustomerToken: '0a96881b-295a-4154-a867-865fc912619c',
            },
        },
    },
    {
        type: 'walley:order:reauthorized',
        payload: {
            Type: 'walley:order:reauthorized',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Amount: 10,
                OriginalAmount: 20,
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:extended',
        payload: {
            Type: 'walley:order:extended',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Amount: 10,
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:captured',
        payload: {
            Type: 'walley:order:captured',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                CaptureId: '147819779',
                Amount: 10,
                AmountLeftToCapture: 20,
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:canceled',
        payload: {
            Type: 'walley:order:canceled',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Amount: 10,
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:expired',
        payload: {
            Type: 'walley:order:expired',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Amount: 10,
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:rejected',
        payload: {
            Type: 'walley:order:canceled',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Amount: 10,
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:service-invoice-paid',
        payload: {
            Type: 'walley:order:service-invoice-paid',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Reference: '01478520',
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:order:advance-invoice-paid',
        payload: {
            Type: 'walley:order:advance-invoice-paid',
            Timestamp: '2024-01-30T11:02:42.5997621+01:00',
            Payload: {
                OrderId: '45e0832b-0b32-43e4-99b2-b10700a58a04',
                Reference: '01478520',
                Currency: 'SEK',
            },
        },
    },
    {
        type: 'walley:authorization:created',
        payload: {
            Type: 'walley:authorization:created',
            Timestamp: '2024-01-30T10:02:31.2700113+00:00',
            Payload: {
                CustomerToken: '0a96881b-295a-4154-a867-865fc912619c',
                AuthorizationId: 'c31e9218-6a19-4b2f-8992-affc9dca1c41',
                Reference: '01478520',
                ActionReference: 'Action 1234',
                CreatedOn: '2024-01-30T10:02:31.2700113+00:00',
            },
        },
    },
    {
        type: 'walley:authorization:authorized',
        payload: {
            Type: 'walley:authorization:authorized',
            Timestamp: '2024-01-30T10:02:31.2700113+00:00',
            Payload: {
                CustomerToken: '0a96881b-295a-4154-a867-865fc912619c',
                AuthorizationId: 'c31e9218-6a19-4b2f-8992-affc9dca1c41',
                Reference: '01478520',
                ActionReference: 'Action 1234',
                CreatedOn: '2024-01-30T10:02:31.2700113+00:00',
            },
        },
    },
    {
        type: 'walley:authorization:retrying',
        payload: {
            Type: 'walley:authorization:retrying',
            Timestamp: '2024-01-30T10:02:31.2700113+00:00',
            Payload: {
                CustomerToken: '0a96881b-295a-4154-a867-865fc912619c',
                AuthorizationId: 'c31e9218-6a19-4b2f-8992-affc9dca1c41',
                Reference: '01478520',
                ActionReference: 'Action 1234',
                CreatedOn: '2024-01-30T10:02:31.2700113+00:00',
                CurrentAttempt: 1,
                MaxAttempt: 5,
                Reason: 'SERVICE_UNAVAILABLE',
            },
        },
    },
    {
        type: 'walley:authorization:failed',
        payload: {
            Type: 'walley:authorization:failed',
            Timestamp: '2024-01-30T10:02:31.2700113+00:00',
            Payload: {
                CustomerToken: '0a96881b-295a-4154-a867-865fc912619c',
                AuthorizationId: 'c31e9218-6a19-4b2f-8992-affc9dca1c41',
                Reference: '01478520',
                ActionReference: 'Action 1234',
                CreatedOn: '2024-01-30T10:02:31.2700113+00:00',
                CurrentAttempt: 1,
                MaxAttempt: 5,
                Reason: 'PAYMENT_METHOD_EXPIRED',
            },
        },
    },
];

const TestWebhookForm: React.FC<FormProps> = ({ onSave, hide, webhook, theme }: FormProps) => {
    const { isTesting, tested, testError } = useAppState<WebhookState>(s => s.admin.webhooks);

    const [testEvent, setTestEvent] = useState<string>('');
    const [testEventBody, setTestEventBody] = useState<string>('');
    const [authorizationMethod, setAuthorizationMethod] = useState(
        webhook?.authorizationMethod ?? ''
    );

    const { handleSubmit } = useForm<Inputs>();

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>
                    {tested ? 'Your test event has been sent' : 'Test webhook'}
                </HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                {!tested && (
                    <form
                        onSubmit={handleSubmit(() => webhook && onSave(webhook, testEventBody))}
                        data-testid="webhook-test-form"
                        id="webhook-test-form"
                    >
                        <FormRow first>
                            <GridInput
                                type="url"
                                id="url"
                                placeholder="Webhook Endpoint"
                                disabled={true}
                                defaultValue={webhook?.url}
                            />
                        </FormRow>
                        <FormRow last={authorizationMethod === AuthorizationMethod.None}>
                            <SelectInput
                                options={[AuthorizationMethod.None, AuthorizationMethod.Basic].map(
                                    val => ({ label: val, value: val })
                                )}
                                onChange={auth =>
                                    setAuthorizationMethod(auth as AuthorizationMethod)
                                }
                                value={authorizationMethod}
                                placeholder="Authentication"
                                defaultValue={AuthorizationMethod.None}
                                disabled={true}
                                dataTestId="webhook-test-events-list"
                            />
                        </FormRow>
                        <FormRow>
                            <SelectInput
                                options={events
                                    .filter(e => webhook?.subscribedEvents.includes(e.type))
                                    .map(e => ({ value: e.type, label: e.type }))}
                                onChange={event => {
                                    setTestEvent(event);
                                    setTestEventBody(
                                        JSON.stringify(
                                            events.find(e => e.type == event)?.payload ?? '',
                                            undefined,
                                            2
                                        )
                                    );
                                }}
                                placeholder="Event"
                                disabled={false}
                                value={testEvent}
                                defaultValue={''}
                            />
                        </FormRow>
                        <FormRow>
                            <GridTextArea
                                onChange={() => {}}
                                name={'Event'}
                                rows={30}
                                value={testEventBody}
                                id="eventInput"
                            />
                        </FormRow>
                    </form>
                )}
                {tested && (
                    <>
                        <p>Response code:</p>
                        <strong>{tested.data}</strong>
                        <p>
                            For a successful webhook, ensure it&apos;s acknowledged with a 200
                            response code; otherwise, the request will be retried. Please consult
                            our documentation for further details.
                        </p>
                    </>
                )}
            </ModalContainer>

            {testError && (
                <ModalContainer position="error">
                    <ErrorMessage error={testError} errorHeader={ErrorType.Webhook} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large disabled={isTesting}>
                        {tested ? 'Close' : 'Cancel'}
                    </Button>
                    {!tested && (
                        <Button
                            tabIndex={0}
                            data-testid="webhook-test-submit"
                            form="webhook-test-form"
                            type="submit"
                            primary
                            large
                            disabled={isTesting}
                        >
                            {isTesting ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Test</span>
                            )}
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(TestWebhookForm) as React.ComponentType<Omit<FormProps, 'theme'>>;

const GridTextArea = styled.textarea`
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    resize: none;
    padding: 0 1.6rem;
    box-sizing: border-box;

    &:focus {
        outline: none !important;
        border: 0.2rem solid ${props => props.theme.colors.primary};
        border-radius: 0.3rem;
    }
`;
