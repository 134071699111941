import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DefaultTheme, withTheme } from 'styled-components';
import { WebhookState } from '../../../../store/admin/webhooks/webhooksSlice';
import { useAppState } from '../../../../store/appstate';
import { ConnectedStore } from '../../../../types/ConnectedStore';
import FormRow from '../../../atoms/Form/FormRow';
import GridInput from '../../../atoms/Input/GridInput';
import SelectInput from '../../../atoms/Input/SelectInput';
import HeaderTitle from '../../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../../atoms/Modal/ModalContainer';
import MultiSelectInput from '../../../atoms/Input/MultiSelectInput';
import StoreLabel, { LabelTypes } from '../../../atoms/Store/StoreLabel';
import FooterButtonContainer from '../../../atoms/Modal/FooterButtonContainer';
import Button from '../../../atoms/Button/Button';
import Spinner from '../../../atoms/Spinner/Spinner';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import { WebhookData } from '../../../../store/admin/webhooks/actions';
import CopyToClipboard from '../../../atoms/CopyToClipboard/CopyToClipboard';
import ValidationError from '../../../atoms/Validation/ValidationError';

interface FormProps {
    onSave: (value: WebhookData) => void;
    hide: () => void;
    theme: DefaultTheme;
}

type Inputs = {
    url: string;
    basicUsername?: string;
    basicPassword?: string;
};

enum AuthorizationMethod {
    None = 'None',
    Basic = 'Basic',
}

const events = [
    'walley:order:created',
    'walley:order:expired',
    'walley:order:reauthorized',
    'walley:order:extended',
    'walley:order:captured',
    'walley:order:canceled',
    'walley:order:rejected',
    'walley:order:service-invoice-paid',
    'walley:order:advance-invoice-paid',
    'walley:authorization:created',
    'walley:authorization:authorized',
    'walley:authorization:retrying',
    'walley:authorization:failed',
];

const CreateWebhookForm: React.FC<FormProps> = ({ onSave, hide, theme }: FormProps) => {
    const { isCreating, created, createdError } = useAppState<WebhookState>(s => s.admin.webhooks);
    const availableStores = useAppState<ConnectedStore[]>(s => s.session.availableStores);

    const [subscribedEvents, setSubscribedEvents] = useState<string[]>([]);
    const [storeIds, setStoreIds] = useState<string[]>([]);
    const [authorizationMethod, setAuthorizationMethod] = useState(AuthorizationMethod.None);

    const {
        register,
        handleSubmit,
        formState: { isSubmitted, errors },
    } = useForm<Inputs>();
    const url = register('url', {
        required: true,
        validate: (value, formValues) =>
            value.startsWith('https://')
                ? undefined
                : 'Please ensure the URL begins with &apos;https://&apos; to establish a secure connection.',
    });
    const basicUsername = register('basicUsername', {
        required: authorizationMethod === AuthorizationMethod.Basic,
    });
    const basicPassword = register('basicPassword', {
        required: authorizationMethod === AuthorizationMethod.Basic,
    });

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>{created ? 'Your webhook is saved' : 'Add webhook'}</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                {!created && (
                    <form
                        onSubmit={handleSubmit((data: Inputs) =>
                            onSave({
                                url: data.url,
                                subscribedEvents: subscribedEvents,
                                storeIds: storeIds,
                                basicAuth:
                                    authorizationMethod === AuthorizationMethod.Basic
                                        ? `${data.basicUsername}:${data.basicPassword}`
                                        : undefined,
                                enabled: true,
                            })
                        )}
                        data-testid="form"
                        id="webhook-form"
                    >
                        <FormRow first>
                            <GridInput
                                type="url"
                                id="url"
                                placeholder="Webhook Endpoint"
                                setRef={url.ref}
                                name={url.name}
                                onChange={url.onChange}
                                disabled={isCreating}
                                validationError={!!errors.url}
                            />
                        </FormRow>
                        <FormRow>
                            <MultiSelectInput
                                options={events.map(e => ({ value: e, label: e }))}
                                values={subscribedEvents}
                                onChange={setSubscribedEvents}
                                placeholder="Events"
                                validationError={isSubmitted && subscribedEvents.length < 1}
                                disabled={isCreating}
                            />
                        </FormRow>
                        <FormRow first>
                            <MultiSelectInput
                                options={availableStores.map(store => ({
                                    value: store.id,
                                    label: (
                                        <StoreLabel
                                            countryCode={store.countryCode}
                                            customerSalesType={store.customerSalesType}
                                            labelType={LabelTypes.LABEL}
                                            storeId={store.id}
                                            storeName={store.storeName}
                                        />
                                    ),
                                }))}
                                values={storeIds}
                                onChange={setStoreIds}
                                placeholder={'Stores'}
                                validationError={isSubmitted && storeIds.length < 1}
                                disabled={isCreating}
                            />
                        </FormRow>
                        <FormRow last={authorizationMethod === AuthorizationMethod.None}>
                            <SelectInput
                                options={[AuthorizationMethod.None, AuthorizationMethod.Basic].map(
                                    val => ({ label: val, value: val })
                                )}
                                onChange={auth =>
                                    setAuthorizationMethod(auth as AuthorizationMethod)
                                }
                                value={authorizationMethod}
                                placeholder="Authentication"
                                defaultValue={AuthorizationMethod.None}
                                disabled={isCreating}
                            />
                        </FormRow>
                        {authorizationMethod === AuthorizationMethod.Basic && (
                            <FormRow last>
                                <GridInput
                                    placeholder="Username"
                                    setRef={basicUsername.ref}
                                    name={basicUsername.name}
                                    onChange={basicUsername.onChange}
                                    disabled={isCreating}
                                />
                                <GridInput
                                    placeholder="Password"
                                    setRef={basicPassword.ref}
                                    name={basicPassword.name}
                                    onChange={basicPassword.onChange}
                                    disabled={isCreating}
                                />
                            </FormRow>
                        )}
                    </form>
                )}

                {created && (
                    <>
                        <strong>HMAC key</strong>
                        <p>
                            We have created a HMAC key for your webhook. You can use it to verify
                            that the webhook is sent from us.
                        </p>
                        <p>It will only be shown once, remember to save it for future use.</p>

                        <FormRow last>
                            <GridInput
                                type="text"
                                placeholder="HMAC key"
                                value={created.hmacKey}
                                readOnly
                                placeholderRaised
                                decorator={<CopyToClipboard value={created.hmacKey} />}
                            />
                        </FormRow>
                    </>
                )}
            </ModalContainer>

            {createdError && (
                <ModalContainer position="error">
                    <ErrorMessage error={createdError} errorHeader={ErrorType.Webhook} />
                </ModalContainer>
            )}
            {errors.url && (
                <ModalContainer position="error">
                    <ValidationError>{errors.url.message}</ValidationError>
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large disabled={isCreating}>
                        {created ? 'Close' : 'Cancel'}
                    </Button>
                    {!created && (
                        <Button
                            tabIndex={0}
                            form="webhook-form"
                            type="submit"
                            primary
                            large
                            disabled={
                                isCreating || storeIds.length < 1 || subscribedEvents.length < 1
                            }
                        >
                            {isCreating ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Create</span>
                            )}
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(CreateWebhookForm) as React.ComponentType<Omit<FormProps, 'theme'>>;
